<template>
    <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :width="width"
        :height="height"
    />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    name: 'BarGraph',
    components: { Bar },
    props: {
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        chart: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            chartData: {
                labels: this.chart.label,
                datasets: this.chart.value,
            },
            chartOptions: {
                responsive: true,
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        stacked: true
                    }
                },
                barPercentage: 0.5,
            }
        }
    },
}
</script>