import Enum from './enum'

/**
 * リストマスタ ステータス検索条件
 */
class Operator extends Enum {
    static CONTAIN = 1;
    static EXCLUDE = 2;

    static values() {
        return {
            [this.CONTAIN]: '含む',
            [this.EXCLUDE]: '含まない',
        }
    }
}

export default Operator;
