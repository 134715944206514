import Enum from './enum'

/**
 * 並び順
 */
class ProjectOrderBy extends Enum {
    static NEXT_APPROACHING_DATE_ASC = 1;
    static NEXT_APPROACHING_DATE_DESC = 2;
    static EXPECTED_ORDER_DATE_ASC = 3;
    static EXPECTED_ORDER_DATE_DESC = 4;
    static CONTACT_DATE_DESC = 5;
    static CONTACT_DATE_ASC = 6;
    static UPDATED_DATETIME_DESC = 7;
    static UPDATED_DATETIME_ASC = 8;
    static CERTIFICATION_EXPIRY_DATE_DESC = 9;
    static CERTIFICATION_EXPIRY_DATE_ASC = 10;
    static SALES_USER_ID_ASC = 11;
    static FIRST_APPOINTMENT_DATETIME_DESC = 12;
    static FIRST_APPOINTMENT_DATETIME_ASC = 13;
    static LATEST_APPOINTMENT_DATETIME_DESC = 14;
    static LATEST_APPOINTMENT_DATETIME_ASC = 15;
    static LATEST_UPDATE_STATUS_DATE_DESC = 16;
    static LATEST_UPDATE_STATUS_DATE_ASC = 17;
    static ORDER_DATE_ASC = 18;
    static ORDER_DATE_DESC = 19;
    static FIRST_MAKING_APPOINTMENT_DATE_DESC = 20;
    static FIRST_MAKING_APPOINTMENT_DATE_ASC = 21;
    static LATEST_MAKING_APPOINTMENT_DATE_DESC = 22;
    static LATEST_MAKING_APPOINTMENT_DATE_ASC = 23;

    static values() {
        return {
            [this.NEXT_APPROACHING_DATE_ASC]: '次回アプローチ日（近い順）',
            [this.NEXT_APPROACHING_DATE_DESC]: '次回アプローチ日（遠い順）',
            [this.EXPECTED_ORDER_DATE_ASC]: '見込日（近い順）',
            [this.EXPECTED_ORDER_DATE_DESC]: '見込日（遠い順）',
            [this.CONTACT_DATE_DESC]: '反響日（新しい順）',
            [this.CONTACT_DATE_ASC]: '反響日（古い順）',
            [this.UPDATED_DATETIME_DESC]: '最終更新日（新しい順）',
            [this.UPDATED_DATETIME_ASC]: '最終更新日（古い順）',
            [this.CERTIFICATION_EXPIRY_DATE_DESC]: '認証有効期限日（新しい順）',
            [this.CERTIFICATION_EXPIRY_DATE_ASC]: '認証有効期限日（古い順）',
            [this.SALES_USER_ID_ASC]: '営業担当順',
            [this.FIRST_APPOINTMENT_DATETIME_DESC]: '初回アポイント日（新しい順）',
            [this.FIRST_APPOINTMENT_DATETIME_ASC]: '初回アポイント日（古い順）',
            [this.LATEST_APPOINTMENT_DATETIME_DESC]: '最新アポイント日（新しい順）',
            [this.LATEST_APPOINTMENT_DATETIME_ASC]: '最新アポイント日（古い順）',
            [this.LATEST_UPDATE_STATUS_DATE_DESC]: 'ステータス変更日（新しい順）',
            [this.LATEST_UPDATE_STATUS_DATE_ASC]: 'ステータス変更日（古い順）',
            [this.ORDER_DATE_ASC]: '受注日（古い順）',
            [this.ORDER_DATE_DESC]: '受注日（新しい順）',
            [this.FIRST_MAKING_APPOINTMENT_DATE_DESC]: '初回アポ取得日（新しい順）',
            [this.FIRST_MAKING_APPOINTMENT_DATE_ASC]: '初回アポ取得日（古い順）',
            [this.LATEST_MAKING_APPOINTMENT_DATE_DESC]: '最新アポ取得日（新しい順）',
            [this.LATEST_MAKING_APPOINTMENT_DATE_ASC]: '最新アポ取得日（古い順）',
        }
    }
}

export default ProjectOrderBy;
