import Enum from './enum'

/**
 * リストマスタ 検索条件
 */
class Operator extends Enum {
    static CONTAIN = 1;
    static EXCLUDE = 2;
    static UNSET = 3;

    static values() {
        return {
            [this.CONTAIN]: '含む',
            [this.EXCLUDE]: '含まない',
            [this.UNSET]: '未設定',
        }
    }
}

export default Operator;
