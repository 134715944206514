<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> レポート - 着地予想</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="condition.department_id"
                    :options="options_department"
                ></form-select>
            </div>
            <div class="col-6">
                <label class="form-label">集計期間</label>
                <form-select
                    v-model="condition.forecast_term"
                    :options="ForecastTerm.options()"
                ></form-select>
            </div>
            <div class="col-12">
                <div class="input-group">
                    <form-input-date v-model="condition.forecast_term_from" :required="true"></form-input-date>
                    <span class="input-group-text">〜</span>
                    <form-input-date v-model="condition.forecast_term_to" :required="true"></form-input-date>
                </div>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <template v-else>
            <!-- グラフ出力 -->
            <div class="col-36 mb-5">
                <bar-graph v-if="chart !== null" :chart="chart" :width="1920" :height="500"></bar-graph>
            </div>

            <!-- 表出力 -->
            <div class="col-36 pt-5">
                <table class="table table-bordered">
                    <thead>
                        <tr class="table-dark ">
                            <th class="text-center"></th>
                            <th class="text-center">着地予想（評価）</th>
                            <th class="text-center" colspan="2">コナン</th>
                            <th class="text-center" colspan="2">見込</th>
                            <th class="text-center" colspan="2">受注</th>
                            <th class="text-center" colspan="2">合計</th>
                        </tr>
                        <tr class="table-dark">
                            <th class="text-center">着地予想（日付）</th>
                            <th class="text-center">確実度</th>
                            <th class="text-center">着地予想</th>
                            <th class="text-center">計</th>
                            <th class="text-center">着地予想</th>
                            <th class="text-center">計</th>
                            <th class="text-center">着地予想</th>
                            <th class="text-center">計</th>
                            <th class="text-center">着地予想</th>
                            <th class="text-center">計</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="forecast in forecasts" :key="forecast.term_label">
                            <template v-for="(certainty, index) in forecast.certainties" :key="certainty.certainty_name">
                                <tr>
                                    <template v-if="index === 0">
                                        <td class="align-middle px-4" :rowspan="forecast.certainties.length + 1">{{ forecast.term_label }}</td>
                                    </template>
                                    <td class="align-middle px-4">{{ certainty.certainty_name }}</td>
                                    <td class="align-middle px-4 text-end">{{ certainty.ranks[0].forecast }}</td>
                                    <td class="align-middle px-4 text-end">
                                        <!-- コナン 各確実度 -->
                                        <button
                                            v-if="certainty.ranks[0].count > 0"
                                            @click="openProjectListModal(
                                                forecast.term_label,
                                                forecast.term_from,
                                                forecast.term_to,
                                                null,
                                                {rank_id: Rank.CONAN, rank_name: 'コナン'},
                                                certainty,
                                                false,
                                            )"
                                            type="button"
                                            class="btn btn-link p-0"
                                            data-bs-toggle="modal"
                                            :data-bs-target="`#projectListModal`"
                                        >{{ certainty.ranks[0].count }}</button>
                                        <span v-else>{{ certainty.ranks[0].count }}</span>
                                    </td>
                                    <td class="align-middle px-4 text-end">{{ certainty.ranks[1].forecast }}</td>
                                    <td class="align-middle px-4 text-end">
                                        <!-- 見込 各確実度 -->
                                        <button
                                            v-if="certainty.ranks[1].count > 0"
                                            @click="openProjectListModal(
                                                forecast.term_label,
                                                forecast.term_from,
                                                forecast.term_to,
                                                null,
                                                {rank_id: Rank.PROSPECT, rank_name: '見込'},
                                                certainty,
                                                false,
                                            )"
                                            type="button"
                                            class="btn btn-link p-0"
                                            data-bs-toggle="modal"
                                            :data-bs-target="`#projectListModal`"
                                        >{{ certainty.ranks[1].count }}</button>
                                        <span v-else>{{ certainty.ranks[1].count }}</span>
                                    </td>
                                    <td class="align-middle px-4 text-end">{{ certainty.ranks[2].forecast }}</td>
                                    <td class="align-middle px-4 text-end">
                                        <!-- 受注 各確実度 -->
                                        <button
                                            v-if="certainty.ranks[2].count > 0"
                                            @click="openProjectListModal(
                                                forecast.term_label,
                                                forecast.term_from,
                                                forecast.term_to,
                                                {status_id: Status.STARTED, status_name: '受注'},
                                                null,
                                                certainty,
                                                false,
                                            )"
                                            type="button"
                                            class="btn btn-link p-0"
                                            data-bs-toggle="modal"
                                            :data-bs-target="`#projectListModal`"
                                        >{{ certainty.ranks[2].count }}</button>
                                        <span v-else>{{ certainty.ranks[2].count }}</span>
                                    </td>
                                    <td class="align-middle px-4 text-end table-light">{{ certainty.subtotal.forecast }}</td>
                                    <td class="align-middle px-4 text-end table-light">{{ certainty.subtotal.count }}</td>
                                </tr>
                            </template>
                            <tr class="table-primary">
                                <td class="align-middle px-4">小計</td>
                                <td class="align-middle px-4 text-end">{{ forecast.total.ranks[0].forecast }}</td>
                                <td class="align-middle px-4 text-end">
                                    <!-- コナン 小計 -->
                                    <button
                                        v-if="forecast.total.ranks[0].count > 0"
                                        @click="openProjectListModal(
                                            forecast.term_label,
                                            forecast.term_from,
                                            forecast.term_to,
                                            null,
                                            {rank_id: Rank.CONAN, rank_name: 'コナン'},
                                            null,
                                            true,
                                        )"
                                        type="button"
                                        class="btn btn-link p-0"
                                        data-bs-toggle="modal"
                                        :data-bs-target="`#projectListModal`"
                                    >{{ forecast.total.ranks[0].count }}</button>
                                    <span v-else>{{ forecast.total.ranks[0].count }}</span>
                                </td>
                                <td class="align-middle px-4 text-end">{{ forecast.total.ranks[1].forecast }}</td>
                                <td class="align-middle px-4 text-end">
                                    <!-- 見込 小計 -->
                                    <button
                                        v-if="forecast.total.ranks[1].count > 0"
                                        @click="openProjectListModal(
                                            forecast.term_label,
                                            forecast.term_from,
                                            forecast.term_to,
                                            null,
                                            {rank_id: Rank.PROSPECT, rank_name: '見込'},
                                            null,
                                            true,
                                        )"
                                        type="button"
                                        class="btn btn-link p-0"
                                        data-bs-toggle="modal"
                                        :data-bs-target="`#projectListModal`"
                                    >{{ forecast.total.ranks[1].count }}</button>
                                    <span v-else>{{ forecast.total.ranks[1].count }}</span>
                                </td>
                                <td class="align-middle px-4 text-end">{{ forecast.total.ranks[2].forecast }}</td>
                                <td class="align-middle px-4 text-end">
                                    <!-- 受注 小計 -->
                                    <button
                                        v-if="forecast.total.ranks[2].count > 0"
                                        @click="openProjectListModal(
                                            forecast.term_label,
                                            forecast.term_from,
                                            forecast.term_to,
                                            {status_id: Status.STARTED, status_name: '受注'},
                                            null,
                                            null,
                                            true,
                                        )"
                                        type="button"
                                        class="btn btn-link p-0"
                                        data-bs-toggle="modal"
                                        :data-bs-target="`#projectListModal`"
                                    >{{ forecast.total.ranks[2].count }}</button>
                                    <span v-else>{{ forecast.total.ranks[2].count }}</span>
                                </td>
                                <td class="align-middle px-4 text-end">{{ forecast.total.subtotal.forecast }}</td>
                                <td class="align-middle px-4 text-end">{{ forecast.total.subtotal.count }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <!-- 各数値クリック時OPENモーダル -->
            <!--
                bootstrapのモーダル機能を利用している
                showクラスが追加されると開く
                class="modal fade show"
            -->
            <div class="modal fade" :id="`projectListModal`" tabindex="-1" :aria-labelledby="`projectListModal`" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title" :id="`activityModalLabel`">案件一覧 - {{ modal_title }}</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body mb-5">
                            <div v-if="modal_projects_list.length">
                                <table class="table table-sm">
                                    <thead>
                                        <tr class="table-dark table-head">
                                            <th></th>
                                            <th class="text-center w-ms">会社名</th>
                                            <th class="text-center w-ss">客先担当者</th>
                                            <th class="text-center w-ms">事業部</th>
                                            <th class="text-center w-ss">追客担当</th>
                                            <th class="text-center w-ss">ステータス</th>
                                            <th class="text-center w-ss" v-if="modal_type === 'prospect'">見込日</th>
                                            <th class="text-center w-ss" v-if="modal_type === 'ordered'">受注日</th>
                                            <th class="text-center w-ss">評価</th>
                                            <th class="text-center w-mm">規格</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="project in modal_projects_list" :key="project.project_id">
                                            <td @click.stop>
                                                <router-link :to="{name: 'ProjectEdit', params: {id: project.project_id}}" class="btn btn-link" target="_blank"><i class="bi bi-box-arrow-up-right"></i></router-link>
                                            </td>
                                            <td>{{ project.customer.company_name }}</td>
                                            <td>{{ project.primary_contact ? project.primary_contact.contact_name : '' }}</td>
                                            <td>{{ project.department ? project.department.department_name : '' }}</td>
                                            <td>{{ project.follow_user ? project.follow_user.user_name : '' }}</td>
                                            <td>{{ project.status_label }}</td>
                                            <td v-if="modal_type === 'prospect'">{{ project.expected_order_date_label }}</td>
                                            <td v-if="modal_type === 'ordered'">{{ project.ordered_date_label }}</td>
                                            <td>{{ project.rank_label }}</td>
                                            <td>{{ project.standards_label_all }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else>
                                <p class="alert alert-secondary">{{ modal_loading ? '検索中です' : '※案件が存在しません' }}</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary mb-0" data-bs-dismiss="modal">閉じる</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>

</template>

<script>
import Department from '@/models/entities/department';
import Project from '@/models/entities/project';
import Operator from '@/models/enums/operator';
import Rank from '@/models/enums/rank';
import Status from '@/models/enums/status';
import ProjectOrderBy from '@/models/enums/project-order-by';
import StatusOperator from '@/models/enums/status-operator';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormSelect from '@/components/forms/FormSelect';
import FormInputDate from '@/components/forms/FormInputDate';
import ForecastTerm from '@/models/enums/forecast-term';
import BarGraph from '@/components/reports/BarGraph';

export default {
    name: 'ReportForecast',
    components: {
        InlineLoader,
        FormSelect,
        FormInputDate,
        BarGraph,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,
            modal_loading: false,

            //enum
            Rank,
            Status,

            // 検索フォーム
            options_department: [],
            exists_options_department: false,
            ForecastTerm,
            months: [],

            // 検索条件
            condition: {
                department_id: null,
                forecast_term: null,
                forecast_term_from: null,
                forecast_term_to: null,
            },

            // 表示データ
            forecasts: [],
            chart: null,

            // モーダル
            modal_title: null,
            modal_type: null, // 'ordered' | 'prospect'
            modal_projects_list: [],
        }
    },
    mounted() {
        this.setTerm();
        this.init();
    },
    methods: {
        setTerm() {
            let today = new Date();
            today.setDate(1);
            this.condition.forecast_term_from = this.$helper.date('Y-m-d', today);
            today.setFullYear(today.getFullYear() + 1);
            today.setDate(0);
            this.condition.forecast_term_to = this.$helper.date('Y-m-d', today);
        },
        init() {
            this.startScreenLoading();
            this.loading_count++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                //事業部未登録の場合
                if (this.options_department.length === 0) {
                    this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    this.$router.push({name: 'MasterDepartment'});
                }

                // 事業部初期値セット
                if (this.$store.state.auth.department_id) {
                    this.condition.department_id = this.$store.state.auth.department_id;
                } else {
                    this.condition.department_id = this.options_department[0].value;
                }

                if (this.condition.forecast_term === null) {
                    this.condition.forecast_term = ForecastTerm.MONTHLY;
                }

                this.search();
            })
            .finally(() => {
                //ローディング終了
                this.endScreenLoading();
                this.loading_count--;
            })
        },
        // 検索
        search() {
            //着地予測の件数リスト取得
            this.fetchForecast();

            //着地予想レポート取得
            this.fetchForecastChart();
        },
        // 着地予測の件数リスト取得
        fetchForecast() {
            // ローディング開始
            this.loading_count++;
            //表示データ初期化
            this.forecasts.splice(0);

            this.$http.get('/report/forecast', {
                params: this.condition,
            })
            .then(response => {
                this.forecasts = response.data;
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        fetchForecastChart() {
            // ローディング開始
            this.loading_count++;
            //表示データ初期化
            this.chart = null;

            this.$http.get('/report/forecast/chart', {
                params: this.condition,
            })
            .then(response => {
                this.chart = response.data;
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },

        //指定数値の案件一覧モーダルOPEN
        openProjectListModal(term_label, term_from, term_to, status, rank, certainty, is_total_row) {
            this.modal_loading = true;
            // モーダルの案件一覧をリセット
            this.modal_projects_list.splice(0);

            // モーダルタイトル生成
            let th_column = null;
            if (!status) {
                th_column = rank.rank_name;
            } else {
                th_column = status.status_name;
            }
            let certainty_name = null;
            if (is_total_row === false) { //小計でない場合
                certainty_name = certainty.certainty_name;
            } else {
                certainty_name = '小計';
            }
            let title_row = [term_label, th_column, certainty_name];
            this.modal_title = title_row.join(" | ");
            this.startScreenLoading();

            // 検索条件
            let project_condition = {
                per_page: 9999
            };
            //[共通] 事業部
            project_condition.department_id = this.condition.department_id;

            // 各行の場合（集計行の場合は検索しない）
            if (is_total_row === false) {
                //[共通] 確実度
                project_condition.certainty_operator = Operator.CONTAIN; //含む
                project_condition.certainty_in = [certainty.certainty_id]; //指定の確実度
            }

            //[評価: コナン・見込]の場合
            if (rank !== null && (rank.rank_id === Rank.PROSPECT || rank.rank_id === Rank.CONAN)) {
                project_condition.rank_operator = Operator.CONTAIN; //含む
                project_condition.rank_in = [rank.rank_id]; //コナン or 見込 の評価ID

                //ステータス: [他社落ち、受注、追客なし、自社運用、取得見送り]以外のもの
                project_condition.status_operator = StatusOperator.EXCLUDE; //含まない
                project_condition.status_in = [
                    Status.OTHER_COMPANY,
                    Status.STARTED,
                    Status.NOT_FOLLOW,
                    Status.SELF_OPERATION,
                    Status.PASS
                ]; //これらステータスを含まない

                //日付範囲
                project_condition.expected_order_date_is_null = false;
                project_condition.expected_order_date_from = term_from;
                project_condition.expected_order_date_to = term_to;

                //ソート：見込み日順
                project_condition.orderby = ProjectOrderBy.EXPECTED_ORDER_DATE_ASC;

                this.modal_type = 'prospect';

            //[ステータス: 受注]の場合
            } else if (status !== null && status.status_id === Status.STARTED) {
                //ステータス: 受注
                project_condition.status_operator = Operator.CONTAIN; //含む
                project_condition.status_in = [Status.STARTED];

                //日付範囲
                project_condition.ordered_date_is_null = false;
                project_condition.ordered_date_from = term_from;
                project_condition.ordered_date_to = term_to;

                //ソート：受注日順
                project_condition.orderby = ProjectOrderBy.ORDER_DATE_ASC;

                this.modal_type = 'ordered';
            }

            //案件一覧取得
            this.$http.get('/project', {
                params: project_condition,
            })
            .then((response) => {
                for (let row of response.data.data) {
                    this.modal_projects_list.push(new Project(row));
                }
            })
            .finally(() => {
                this.modal_loading = false;
                this.endScreenLoading();
            })
        },
    }
}
</script>


<style scoped>
    .w-mm {
        width: 16%;
    }
    .w-ms {
        width: 13%;
    }
    .w-ss {
        width: 10%;
    }
    .fs-7 {font-size: .85rem;}
</style>
