import Enum from './enum'

/**
 * 着地予想 : 集計期間
 */
class ForecastTerm extends Enum {
    static MONTHLY = 1; // 月別
    static WEEKLY = 2; // 週別

    static values() {
        return {
            [this.MONTHLY]: '月別',
            [this.WEEKLY]: '週別',
        }
    }
}

export default ForecastTerm;
